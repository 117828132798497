exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-businesses-index-js": () => import("./../../../src/pages/affiliate-businesses/index.js" /* webpackChunkName: "component---src-pages-affiliate-businesses-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-glossary-index-js": () => import("./../../../src/pages/glossary/index.js" /* webpackChunkName: "component---src-pages-glossary-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-timeline-index-js": () => import("./../../../src/pages/timeline/index.js" /* webpackChunkName: "component---src-pages-timeline-index-js" */),
  "component---src-pages-transactions-index-js": () => import("./../../../src/pages/transactions/index.js" /* webpackChunkName: "component---src-pages-transactions-index-js" */),
  "component---src-template-news-index-js": () => import("./../../../src/template/News/index.js" /* webpackChunkName: "component---src-template-news-index-js" */)
}

